import React, {useState, useRef} from 'react'
import { useStaticQuery, graphql } from "gatsby"
import DefaultLayout from '../components/layouts/defaultLayout'
import SEO from '../components/utils/SEO'
import Footer from '../components/footer/footer'
import {
  GrandAtelierWrapper, 
  AtelierSelector, 
  AtelierDetails
} from '../components/pages/grandAtelierWrapper'
import {window} from 'browser-monads'
import theme from '../theme/theme'
import Hero from '../components/hero/hero'

const LeGrandAtelier = () => {
   const data = useStaticQuery(graphql`
   query AtelierQuery {
    ateliers:allStrapiAteliers {
      nodes {
        images {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                presentationWidth
                presentationHeight
              }
            }
          }
        }
        vignette {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
                presentationWidth
                presentationHeight
            }
          }
        }
        description
        titre
      }
    }
    
  }
   `)

  const [selectedAtelier, setSelectedAtelier] = useState(0);
  const gallery = useRef();

  const onAtelierClick = (i) => {
    setSelectedAtelier(i);
    window.scrollTo({
      top: gallery.current.offsetParent.offsetTop,
      behavior: 'smooth'
    })
   
  }

   return(
    <DefaultLayout>
      <GrandAtelierWrapper>
        <SEO title="Ateliers" descirption="Les ateliers proposés par Tribal Cabane" />
        <Hero 
            title="Ateliers" 
            subtitle="Découvrez les ateliers Tribal Cabane"
            color={theme.colors.grandAtelier}
        />
        <AtelierSelector ateliers={data.ateliers.nodes} selectedIndex={selectedAtelier} onClick={(i) => onAtelierClick(i)}/>

        <AtelierDetails ref={gallery} atelier={data.ateliers.nodes[selectedAtelier]} />
      </GrandAtelierWrapper>
    <Footer />
    </DefaultLayout>
    )
}


export default LeGrandAtelier