import React from 'react'
import styled from 'styled-components'
import {lighten} from 'polished'
import Img from 'gatsby-image'
import ImageGallery from '../gallery/imageGallery'
import Container from '../utils/container'
import {down} from 'styled-breakpoints'

const GrandAtelierWrapper = styled.div`
    background: ${p => p.theme.colors.grandAtelier};
    .galery-title{
      color: ${p => p.theme.colors.white};
      text-align: center;
    }
`
const AtelierSelectorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 100px;
  overflow-x: auto;
  ${down('lg')}{
      margin: 0 25px;
  }
  ${down('sm')}{
      margin: 0;
  }
  
`;
const AtelierSelectorAtelier = styled.div`
    width: 33%;
    background: #212527;
    padding: 10px;
    margin: 10px;
    box-shadow: 0 25px 50px -12px rgba(0,0,0,.25);
    border-radius: 5px;
    -webkit-transition: .3s ease-in;
    transition: .3s ease-in;
      
    &.selected, &:hover{
      box-shadow: 0 25px 50px -12px rgba(0,0,0,.25);
      background-color: ${p => lighten(.1, p.theme.colors.grandAtelier)};
    }
    h1{
        color: ${p => p.theme.colors.white};
        font-size: ${p => p.theme.fontSizes.lg};
        text-align: center;
        margin: 0px 0 10px 0;
    }
    >.gatsby-image-wrapper{
        max-height: 400px;
    }
`;

const AtelierDetailsWrapper = styled(Container)`
    padding: 15px;
    margin: 50px auto;
    color: ${p => p.theme.colors.white};
    font-size: ${p => p.theme.fontSizes.lg};
    ${down('md')}{
        margin-left: 15px;
        margin-right: 15px;
    }
    ${down('sm')}{
        margin-left: 5px;
        margin-right: 5px;
    }
`
const AtelierSelector = ({ateliers, selectedIndex, onClick}) => {
    
    return (
      <AtelierSelectorWrapper>
          {ateliers.map((atelier, i) => {
              return(
                  <AtelierSelectorAtelier key={i} className={i === selectedIndex ? "selected" : ""} onClick={() => onClick(i)}>
                      <h1>{atelier.titre}</h1>
                      <Img fluid={atelier.vignette.childImageSharp.fluid} />
                  </AtelierSelectorAtelier>
              )
          })}
      </AtelierSelectorWrapper>
    )
}

const AtelierDetails = React.forwardRef((props, ref) => (
        <>
            <AtelierDetailsWrapper>
                <p ref={ref}>{props.atelier.description}</p>
            </AtelierDetailsWrapper>
            <ImageGallery images={props.atelier.images} />

        </>
    ));


export {GrandAtelierWrapper, AtelierSelectorWrapper, AtelierSelectorAtelier, AtelierSelector, AtelierDetails}